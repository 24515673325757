import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from './api/axios';
import CareerGeniusLogo from './assets/CareerGeniusLogo.png';
import FolderIcon from './assets/FolderIcon.png';
import './UploadResume.css';

const ColdTrafficLandingPage = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRedditUser, setIsRedditUser] = useState(false);

  const handleStartScan = () => {
    navigate('/LoginForm');
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    navigate('/LoginForm');
  };

  useEffect(() => {
    // Log page view
    try {
      const sessionId = localStorage.getItem('sessionId') || 'anonymous-session';
      axios.post('/log-activity', {
        sessionId: sessionId,
        event_type: "Page Landing",
        current_page: 'COLD LANDING PAGE',
        object_type: 'Page',
        specific_object: 'Landed on COLD LANDING PAGE page'
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).catch(err => console.log('Logging error (non-critical):', err));
    } catch (error) {
      console.log('Error logging activity:', error);
    }

    // Detect if Reddit visitor
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("utm_source") === "reddit") {
      setIsRedditUser(true);
    }
  }, []);

  return (
    <div className="home-container">
      <header className="header">
        <img src={CareerGeniusLogo} alt="CareerGenius Logo" className="logo" />
      </header>

      <div className="form-container">
        {isRedditUser && (
          <div style={{ backgroundColor: '#e6f0ff', padding: '10px 15px', borderRadius: '6px', marginBottom: '1.5rem', textAlign: 'center' }}>
            👋 Hey Redditors — you’re in the right place. Start below.
          </div>
        )}

        <h2 style={{ lineHeight: '1.4em' }}>
          Your Resume Probably Isn’t Even Being Seen.
        </h2>

        <p style={{ fontSize: '1.1em' }}>
          CareerGenius scores your resume against real job descriptions — then helps you fix it fast. 
          Upload once. Paste job link. Get feedback + a new resume in seconds.
        </p>

        <div className="upload-container" style={{ margin: '2rem 0' }}>
          <input
            type="file"
            id="fileUpload"
            className="file-input"
            onChange={handleFileChange}
            accept=".pdf, .docx"
          />
          <label htmlFor="fileUpload" className="upload-box">
            <img src={FolderIcon} alt="Upload Folder" className="upload-icon animated-hover" />
            <span className="upload-text">
              Upload Your Resume
            </span>
          </label>
        </div>

        {selectedFile && (
          <p className="file-name">{selectedFile.name}</p>
        )}

        <button 
          className="btn upload-btn" 
          onClick={handleUpload} 
          style={{ padding: '14px 28px', fontSize: '1.1em', marginTop: selectedFile ? '1rem' : '0' }}
        >
          {selectedFile ? 'Scan My Resume Now' : 'Try It Free – No Credit Card Needed'}
        </button>

        <p style={{ fontWeight: 'bold', marginTop: '1.5rem', fontSize: '1.1em' }}>
          Built for job seekers. Instant results.
        </p>

        <div className="upload-note" style={{ fontStyle: 'italic' }}>
          No hoops. Just signup, upload and start optimizing.
        </div>

        <div className="upload-container" style={{ marginTop: '2.5rem', padding: '1.5rem', backgroundColor: 'rgba(240, 240, 240, 0.3)' }}>
          <h3 className="highlight" style={{ marginBottom: '1rem' }}>How CareerGenius Works</h3>

          <ul style={{ textAlign: 'left', margin: '1.2rem 0', lineHeight: '1.6' }}>
            <li><strong>Step 1:</strong> Upload your resume (PDF or Word)</li>
            <li><strong>Step 2:</strong> Paste the job description</li>
            <li><strong>Step 3:</strong> Get your resume scored & rewritten automatically</li>
            <li><strong>Step 4:</strong> Customize with our free editor</li>
            <li><strong>Step 5:</strong> Download or keep refining (premium options available)</li>
          </ul>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2.5rem', padding: '1rem', border: '1px solid #eaeaea', borderRadius: '8px' }}>
          <div className="upload-note" style={{ fontStyle: 'italic', fontSize: '1.1em' }}>
            "After months of silence, CareerGenius showed me exactly what keywords I was missing. I optimized my resume in minutes and landed 4 interviews the next week." 
            <br />
            <strong style={{ marginTop: '0.5rem', display: 'block' }}>– Tina L., Marketing Professional</strong>
          </div>
        </div>

        <div style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ fontWeight: 'bold', color: '#333' }}>
            Join 1,000+ job seekers who stopped getting ignored
          </p>

          <button className="btn upload-btn" style={{ marginTop: '1rem', padding: '16px 32px', fontSize: '1.2em' }} onClick={handleStartScan}>
            Get My Resume Score Now
          </button>

          <div className="upload-note" style={{ marginTop: '0.5rem' }}>
            Unlimited free scans. Try before you subscribe.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColdTrafficLandingPage;
