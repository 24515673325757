import React, { useState, useEffect } from 'react';
import { useAuth } from './context/AuthProvider';
import axios from './api/axios';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useWindowDimensions from './functions/useWindowDimensions.jsx';
import AuthenticatedComponent from './components/AuthenticatedComponent';

const CustomerSupportPage = () => {
  const { auth } = useAuth();
  const [issueType, setIssueType] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    // Log page visit
    axios.post('/log-activity', {
      sessionId: auth.sessionId,
      event_type: "Page Landing",
      current_page: 'CustomerSupportPage',
      object_type: 'Page',
      specific_object: 'Landed on CustomerSupportPage'
    }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset status states
    setSubmitSuccess(false);
    setSubmitError('');
    
    // Validate form
    if (!issueType) {
      setSubmitError('Please select an issue type');
      return;
    }
    
    if (!description || description.trim().length < 10) {
      setSubmitError('Please provide a detailed description (at least 10 characters)');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const response = await axios.post('/submit-support-ticket', {
        sessionId: auth.sessionId,
        username: auth.username,
        issueType: issueType,
        description: description
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true
      });
      
      if (response.data.success) {
        setSubmitSuccess(true);
        // Reset form
        setIssueType('');
        setDescription('');
      } else {
        setSubmitError('Failed to submit support ticket. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting support ticket:', error);
      setSubmitError('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const issueTypes = [
    'Subscription Issue',
    'Payment Problem',
    'Account Access',
    'Feature Request',
    'Bug Report',
    'Resume Assistance',
    'Cover Letter Help',
    'Other'
  ];

  return (
    <div style={{ 
      textAlign: 'left',
      padding: '20px',
      marginLeft: width > 700 ? '20%' : '0',
      marginRight: width > 700 ? '20%' : '0' 
    }}>
      <AuthenticatedComponent />
      
      <Typography variant="h4" component="h1" gutterBottom>
        Customer Support
      </Typography>
      
      <Typography variant="body1" paragraph>
        Need help? Submit a support ticket and our team will get back to you as soon as possible.
      </Typography>
      
      {submitSuccess && (
        <Alert variant="success" onClose={() => setSubmitSuccess(false)} dismissible>
          Your support ticket has been submitted successfully. Our team will review your issue and respond shortly.
        </Alert>
      )}
      
      {submitError && (
        <Alert variant="danger" onClose={() => setSubmitError('')} dismissible>
          {submitError}
        </Alert>
      )}
      
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          mt: 3,
          p: 3,
          border: '1px solid #e0e0e0',
          borderRadius: 2,
          backgroundColor: '#f9f9f9'
        }}
      >
        <Form.Group className="mb-3">
          <Form.Label>Issue Type</Form.Label>
          <Form.Select 
            value={issueType}
            onChange={(e) => setIssueType(e.target.value)}
            required
          >
            <option value="">Select an issue type</option>
            {issueTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Please describe your issue in detail..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>

        <Button 
          variant="primary" 
          type="submit" 
          disabled={isSubmitting}
          className="w-100"
        >
          {isSubmitting ? 'Submitting...' : 'Submit Support Ticket'}
        </Button>
      </Box>
      
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1">
          For urgent matters, please email us at support@careergenius.org in addition to filling out a ticket.
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
          Our customer support team is available Monday-Friday, 9am-5pm EST.
        </Typography>
      </Box>
    </div>
  );
};

export default CustomerSupportPage;