import React,{useState,useEffect,useContext} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LogoutButton from './LogoutButton.jsx';
import AuthContext from "./context/AuthProvider";
import { Dropdown,Button } from 'react-bootstrap';
import StripeSubscriptionButton from './components/StripeSubscriptionButton.jsx';
import CustomerSupportPage from './CustomerSupportPage.jsx';

import "./ToolBar.css";
const ToolBar = () => {
  const { auth,setAuth } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [loggedIn, setLoggedIn] = useState('');

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
      setIsSubscribed(true);
      }
  }, []);
  

  useEffect(() => {
     
    setUsername(localStorage.getItem('username'));
    setLoggedIn(localStorage.getItem('loggedIn'));
  });
  return (
    <nav style={{
      marginLeft: 'auto',
      display: 'flex',
    }}>
      {auth.loggedIn == 1 ? 
        <>
          <Button variant="primary" as={Link} to="/JobDetails" style={{
            fontSize: '16px',
            fontWeight: 'normal',
            color: 'white',
            textDecoration: 'none',
            marginRight: '20px',
          }}>Start New Job App</Button>
          
          <Button variant="btn primary" as={Link} to="/CustomerSupportPage" style={{
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#333',
            textDecoration: 'none',
            marginRight: '20px',
          }}>Customer Support</Button>

          <Button variant="btn primary" as={Link} to="/account" style={{
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#333',
            textDecoration: 'none',
            marginRight: '20px',
          }}>Account</Button>
        </> 
      : null}
    </nav>
  );
};

export default ToolBar;
